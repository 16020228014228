import {Client} from '../../../shared/utilities/type-helpers';
import {environment as env} from './environment.live';
const client: Client = {clientType: 't1'};

export const environment = {
  ...env,
  firebaseConfig: {
    apiKey: 'AIzaSyDUhDl-ZHijxmL4VQVKIyNs4TB7odQAs7I',
    authDomain: 't1paginas-live.firebaseapp.com',
    projectId: 't1paginas-live',
    storageBucket: 't1paginas-live.appspot.com',
    messagingSenderId: '24828658017',
    appId: '1:24828658017:web:e307c112ffb46110098520',
    measurementId: 'G-3M2S38ETKL',
  },
  client,
};
